
.costOfDissertationQuickQueryFormStartHere .quickQueryFormLabel {
    color: #006ca2;
}

  .costOfDissertationQuickQueryFormPartButton {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    background-color: rgba(0, 108, 162, 0.8);
    border: none;
    padding: 2% 5%;
    border-radius: 3px;
  }

  .costOfDissertationQuickQueryFormPart {
    padding: 3% 4%;
    height: auto;
    background: linear-gradient(
      112.98deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .costOfDissertationQuickQueryFormStartHere .quickQueryFormLabel span {
    color: red;
  }

  .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormEmail, .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 100%;
    padding: 2px;
    background: transparent;
  }

  .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }

  .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormSelect {
    padding: 2px 0 !important;
  }
  
  .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormSelect option {
    color: #006CA2 !important;
    font-weight: 500 !important;
  }

  .costOfDissertationQuickQueryFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }

  .hireAnExpertButtonDisplay {
    display: none;
  }

  /* Responsive Dissertation Cost  */

  @media only screen and (max-width: 1199px) {
    .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormEmail, .costOfDissertationQuickQueryFormStartHere .costOfDissertationQuickQueryFormSelect {
      width: 100%;
  }
  }
  @media only screen and (max-width: 991px) {
    .costOfDissertationQuickQueryFormPart {
      width: 500px;
    }
  }
  @media only screen and (max-width: 465px) {
    .costOfDissertationQuickQueryFormStartHere .quickQueryFormLabel {
      font-size: 14px !important;
    }
    .costOfDissertationQuickQueryFormPartButton {
      font-size: 12px;
    }
  }